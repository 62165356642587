import React, { useState } from 'react';
import { createOrUpdateHeaderSetting } from '../../services/api_header_setting';

const HeaderSettingCreate = () => {
  const [formData, setFormData] = useState({
    pictureArtIDList: [],
    desc: { vi: '', en: '' },
    title: { vi: '', en: '' },
  });

  const [inputPictureArtID, setInputPictureArtID] = useState(''); // Temporary input state for Picture Art IDs

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [field]: lang ? { ...prevData[field], [lang]: value } : value,
    }));
  };

  const handlePictureArtIDChange = (e) => {
    setInputPictureArtID(e.target.value); // Update temporary input
  };

  const addPictureArtID = () => {
    const newIDs = inputPictureArtID
      .split(',')
      .map((id) => parseInt(id.trim()))
      .filter((id) => !isNaN(id)); // Ensure valid numbers

    setFormData((prevData) => ({
      ...prevData,
      pictureArtIDList: [...prevData.pictureArtIDList, ...newIDs],
    }));

    setInputPictureArtID(''); // Clear input
  };

  const removePictureArtID = (indexToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      pictureArtIDList: prevData.pictureArtIDList.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createOrUpdateHeaderSetting(formData);
      alert('Header setting created successfully');
      setFormData({
        pictureArtIDList: [],
        desc: { vi: '', en: '' },
        title: { vi: '', en: '' },
      });
    } catch (error) {
      console.error('Error creating header setting:', error);
      alert('Error creating header setting');
    }
  };

  return (
    <div className="w-full flex items-center flex-col bg-gray-100">
      <div className="w-full h-[50px] bg-base_color">
      </div>
      <form onSubmit={handleSubmit} className="w-[50%] mt-[50px] bg-white p-5 shadow rounded">
        <h1 className="text-2xl font-bold mb-5">Create Header Setting</h1>
        <div className="mb-4">
          <label htmlFor="pictureArtIDList" className="block mb-2">
            Add Picture Art IDs (comma-separated)
          </label>
          <div className="flex items-center gap-2">
            <input
              type="text"
              id="pictureArtIDList"
              value={inputPictureArtID}
              onChange={handlePictureArtIDChange}
              placeholder="e.g., 101, 102, 103"
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            />
            <button
              type="button"
              onClick={addPictureArtID}
              className="px-3 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Add
            </button>
          </div>
          <div className="mt-2">
            <ul>
              {formData.pictureArtIDList.map((id, index) => (
                <li key={index} className="flex justify-between items-center mb-1">
                  <span>{id}</span>
                  <button
                    type="button"
                    onClick={() => removePictureArtID(index)}
                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="desc.vi" className="block mb-2">
            Description (Vietnamese)
          </label>
          <input
            type="text"
            id="desc.vi"
            name="desc.vi"
            value={formData.desc.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="desc.en" className="block mb-2">
            Description (English)
          </label>
          <input
            type="text"
            id="desc.en"
            name="desc.en"
            value={formData.desc.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="title.vi" className="block mb-2">
            Title (Vietnamese)
          </label>
          <input
            type="text"
            id="title.vi"
            name="title.vi"
            value={formData.title.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="title.en" className="block mb-2">
            Title (English)
          </label>
          <input
            type="text"
            id="title.en"
            name="title.en"
            value={formData.title.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Create
        </button>
      </form>
    </div>
  );
};

export default HeaderSettingCreate;
