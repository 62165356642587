import { API_URL } from "./api";
// api.js
import axios from 'axios';
// Add the following lines to set the CORS headers
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Replace '*' with the allowed origin(s) of your backend API
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE'; // Specify the allowed HTTP methods
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'; // Specify the allowed headers
export const getMaterials = async () => {
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/materials`);
    // console.log("data-category:", response.data);
     
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  export const createMaterial = async (materialData) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/materials`, materialData);
      console.log("materialData",materialData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const updateMaterial = async (id,materialData) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.put(`${API_URL}/materials/update/${id}`, materialData);
      console.log("materialData",materialData);
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const findMaterialById = async (id) => {
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/materials/${id}`, {
        withCredentials: true,
      });
      
      return response.data;
    } catch (error) {
      throw error;
    }
  
  }

  export const deleteMaterial = async (materialID) => {
    try {
      const response = await axios.put(`${API_URL}/materials/delete/${materialID}`, {
        withCredentials: true,
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  };