import axios from "axios";
import { API_URL } from "./api";

// const API_URL = "http://localhost:8081/api/picture-topic";

const getToken = () => localStorage.getItem("token");

export const findAllPictureTopics = async () => {
  try {
    const response = await axios.post(`${API_URL}/picture-topic/findAll`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findPictureTopicById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/picture-topic/findById`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const insertPictureTopic = async (pictureTopicData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${API_URL}/picture-topic/insert`, pictureTopicData, {
      params: { token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePictureTopic = async (pictureTopicData) => {
  try {
    const token = getToken();
    const response = await axios.post(`${API_URL}/picture-topic/update`, pictureTopicData, {
      params: { token },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePictureTopic = async (id) => {
  try {
    const token = getToken();
    const response = await axios.post(`${API_URL}/picture-topic/delete`, null, {
      params: { token, id },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAllPictureTopics = async () => {
  try {
    const response = await axios.post(`${API_URL}/picture-topic/deleteAll`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// New function to get PictureTopic by key
export const findPictureTopicByKey = async (key) => {
  try {
    const response = await axios.get(`${API_URL}/picture-topic/key/${key}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
