import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findCategory, updateCategory, uploadFile, getGroups, API_URL_IMAGE } from '../../services/api';
import noImage from '../../assets/avatar-default-icon.png';

const CategoryEdit = () => {
  const { id } = useParams(); // Assuming `id` is passed via route params
  const navigate = useNavigate();

  const [filePreview, setFilePreview] = useState(noImage);
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({
    id: 0,
    name: { vi: '', en: '' },
    desc: { vi: '', en: '' },
    thumb: '',
    group: 'space', // Default group
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await findCategory(id);
        setFormData({
          id: id,
          name: {
            vi: response?.name?.vi || '',
            en: response?.name?.en || '',
          },
          desc: {
            vi: response?.desc?.vi || '',
            en: response?.desc?.en || '',
          },
          thumb: response?.thumb || noImage,
          group: response?.group || 'space',
        });
        setFilePreview(response?.thumb || noImage);
      } catch (error) {
        console.error('Error fetching category:', error);
      }
    };

    const fetchGroups = async () => {
      try {
        const groupData = await getGroups();
        setOptions(groupData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchContent();
    fetchGroups();
  }, [id]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const response = await uploadFile(file);
          const fileUrl = response.data;
          setFilePreview(fileUrl);
          setFormData((prev) => ({ ...prev, thumb: fileUrl }));
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prev) => ({
      ...prev,
      [field]: lang ? { ...prev[field], [lang]: value } : value,
    }));
  };

  const handleGroupChange = (e) => {
    setFormData((prev) => ({ ...prev, group: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await updateCategory(formData);
      if (result.status === 200) {
        navigate('/admin/categories');
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  return (
    <div className="flex flex-col w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color" />
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        <h2 className="text-xl font-bold mb-6">Edit Category</h2>

        {/* Name (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Category Name (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Name (English) */}
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Category Name (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Description (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="desc.vi" className="block mb-2 font-medium">
            Description (Vietnamese)
          </label>
          <textarea
            id="desc.vi"
            name="desc.vi"
            value={formData.desc.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows={3}
            required
          />
        </div>

        {/* Description (English) */}
        <div className="mb-4">
          <label htmlFor="desc.en" className="block mb-2 font-medium">
            Description (English)
          </label>
          <textarea
            id="desc.en"
            name="desc.en"
            value={formData.desc.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows={3}
            required
          />
        </div>

        {/* Thumbnail */}
        <div className="mb-4">
          <label htmlFor="thumb" className="block font-medium">Thumbnail</label>
          <input
            type="file"
            id="thumb"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full p-2"
          />
          <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover rounded" />
        </div>

        {/* Group */}
        <div className="mb-4">
          <label className="block mb-2 font-medium">Group</label>
          <div className="flex items-center space-x-4">
            {options.map((option) => (
              <label key={option.value} className="inline-flex items-center">
                <input
                  type="radio"
                  className="text-indigo-600 form-radio"
                  value={option.value}
                  checked={formData.group === option.value}
                  onChange={handleGroupChange}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default CategoryEdit;
