import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Tag, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deletePictureArt, getPictureArtist } from '../../services/api_picture_art';
import { API_URL_IMAGE } from '../../services/api';

const PictureArtList = () => {
  const [pictureArtList, setPictureArtList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  // Fetch data for PictureArt
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPictureArtist();
        setPictureArtList(response.data); // Assuming the response contains the 'data' field with the list
      } catch (error) {
        console.error('Error fetching PictureArt data:', error);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/admin/picture-art/update/${id}`);
  };

  const handleInsert = () => {
    navigate(`/admin/picture-art/create-new`);
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deletePictureArt(deleteId);
      const updatedList = await getPictureArtist();
      setPictureArtList(updatedList.data || []);
      setIsModalVisible(false);
      setDeleteId(null);
    } catch (error) {
      console.error('Error deleting PictureArt:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDeleteId(null);
  };

  // Columns configuration for the table
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Tên tác phẩm (VN)', dataIndex: ['name', 'vi'], key: 'name_vi' },
    { title: 'Tên tác phẩm (EN)', dataIndex: ['name', 'en'], key: 'name_en' },
    { title: 'Artist (VN)', dataIndex: ['artistName', 'vi'], key: 'artist_vi' },
    { title: 'Artist (EN)', dataIndex: ['artistName', 'en'], key: 'artist_en' },
    {
      title: 'Categories',
      dataIndex: 'categoriesID',
      key: 'categoriesID',
      render: (categoryID) => (
        <Tag color="blue">{categoryID}</Tag> // Display categoryID directly
      ),
    },
    {
      title: 'Materials',
      dataIndex: 'materialsID',
      key: 'materialsID',
      render: (materialID) => (
        <Tag color="green">{materialID}</Tag> // Display materialID directly
      ),
    },
    { title: 'Size', dataIndex: 'size', key: 'size' },
    {
      title: 'Thumbnail',
      dataIndex: 'thumb',
      key: 'thumb',
      render: (thumb) => (
        <img
          src={API_URL_IMAGE +  thumb}
          alt="thumbnail"
          style={{ width: 50, height: 50, objectFit: 'cover' }}
        />
      ),
    },
    {
      title: 'Hành động',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="text-white bg-edit"
            type="primary"
            onClick={() => handleEdit(record.id)}
          >
            Cập nhật
          </Button>
          <Button
            className="text-white bg-delete"
            type="danger"
            onClick={() => confirmDelete(record.id)}
          >
            Xóa
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="flex flex-col w-full h-auto bg-white">
      <div className="w-full h-[50px] bg-base_color"></div>
      <div className="flex justify-start mt-5 ml-5">
        <Button
          onClick={handleInsert}
          className="w-[160px] ml-5 font-bold text-center mb-5 bg-blue-500"
          type="primary"
        >
          Tạo mới
        </Button>
      </div>
      <Table
        style={{ width: '100%', fontFamily: 'Courier New' }}
        dataSource={pictureArtList}
        columns={columns}
        rowKey="id"
      />
      <Modal
        title="Xác nhận xóa"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Xóa"
        cancelText="Hủy"
      >
        <p>Bạn có chắc chắn muốn xóa tác phẩm này?</p>
      </Modal>
    </div>
  );
};

export default PictureArtList;
