import axios from 'axios';
import { API_URL } from "./api";

const API_DOMAIN = `${API_URL}/header-settings`; // Adjust the base URL if needed

/**
 * Fetch all header settings.
 * @returns {Promise} List of header settings
 */
export const getAllHeaderSettings = async () => {
  try {
    const response = await axios.get(API_DOMAIN);
    return response.data;
  } catch (error) {
    console.error('Error fetching all header settings:', error);
    throw error;
  }
};

/**
 * Fetch a specific header setting by ID.
 * @param {number} id - The ID of the header setting
 * @returns {Promise} The header setting data
 */
export const getHeaderSettingById = async (id) => {
  try {
    const response = await axios.get(`${API_DOMAIN}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching header setting with ID ${id}:`, error);
    throw error;
  }
};

/**
 * Create or update a header setting.
 * @param {Object} headerSetting - The header setting data to save
 * @returns {Promise} The saved header setting
 */
export const createOrUpdateHeaderSetting = async (headerSetting) => {
  try {
    const response = await axios.post(API_DOMAIN, headerSetting);
    return response.data;
  } catch (error) {
    console.error('Error creating or updating header setting:', error);
    throw error;
  }
};

/**
 * Delete a header setting by ID.
 * @param {number} id - The ID of the header setting to delete
 * @returns {Promise} Void
 */
export const deleteHeaderSetting = async (id) => {
  try {
    await axios.delete(`${API_DOMAIN}/${id}`);
  } catch (error) {
    console.error(`Error deleting header setting with ID ${id}:`, error);
    throw error;
  }
};
