import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL_IMAGE, createCategory, getGroups, uploadFile } from '../../services/api';
import noImage from '../../assets/avatar-default-icon.png';

const CategoryCreate = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(noImage);
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: { vi: '', en: '' },
    desc: { vi: '', en: '' },
    thumb: '',
    group: 'space',  // Default group
  });
  const [filePreview, setFilePreview] = useState(noImage); // Thumbnail preview

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        setFormData((prev) => ({ ...prev, thumb: event.target.result }));

        try {
          const response = await uploadFile(file);
          const fileResponse = response.data;
          setFilePreview(fileResponse);

          setFormData((prevFormData) => ({
            ...prevFormData,
            thumb: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [field]: lang ? { ...prevData[field], [lang]: value } : value,
    }));
  };

  const handleGroupChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await createCategory(formData);
    if (result.success === 200) {
      navigate('/admin/categories');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGroups();
      setOptions(data);
    };
    fetchData();
  }, []);

  return (
    <div className='flex flex-col w-full h-auto bg-[#EEA048]'>

      <div className="w-full h-[50px] bg-base_color">

      </div>
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Tên danh mục (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Tên danh mục (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="desc.vi" className="block mb-2 font-medium">
            Mô tả (Vietnamese)
          </label>
          <input
            type="text"
            id="desc.vi"
            name="desc.vi"
            value={formData.desc.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="desc.en" className="block mb-2 font-medium">
            Mô tả (English)
          </label>
          <input
            type="text"
            id="desc.en"
            name="desc.en"
            value={formData.desc.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="thumb" className="block font-medium">Thumbnail</label>
          <input
            type="file"
            id="thumb"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full p-2"
          />
          <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover" />
        </div>
        <div className="flex items-center space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="text-indigo-600 form-radio"
              value="material"
              checked={formData.group === 'material'}
              onChange={handleGroupChange}
            />
            <span className="ml-2">Material</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="text-indigo-600 form-radio"
              value="subject"
              checked={formData.group === 'subject'}
              onChange={handleGroupChange}
            />
            <span className="ml-2">Subject</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="text-indigo-600 form-radio"
              value="space"
              checked={formData.group === 'space'}
              onChange={handleGroupChange}
            />
            <span className="ml-2">Space</span>
          </label>
        </div>


        <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Tạo mới danh mục
        </button>
      </form>
    </div>
  );
};

export default CategoryCreate;
