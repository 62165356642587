import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMaterial } from '../../services/api_material';

const MaterialCreate = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: { vi: '', en: '' },
    desc: { vi: '', en: '' },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [field]: lang ? { ...prevData[field], [lang]: value } : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await createMaterial(formData);
      if (result.status === 201) { // Ensure the success condition matches your API response
        navigate('/admin/materials');
      }
    } catch (error) {
      console.error('Error creating material:', error);
    }
  };

  return (
    <div className='flex flex-col w-full h-auto bg-[#EEA048]'>
      <div className="w-full h-[50px] bg-base_color" />
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Tên danh mục (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Tên danh mục (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="desc.vi" className="block mb-2 font-medium">
            Mô tả (Vietnamese)
          </label>
          <input
            type="text"
            id="desc.vi"
            name="desc.vi"
            value={formData.desc.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="desc.en" className="block mb-2 font-medium">
            Mô tả (English)
          </label>
          <input
            type="text"
            id="desc.en"
            name="desc.en"
            value={formData.desc.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Tạo danh mục
        </button>
      </form>
    </div>
  );
};

export default MaterialCreate;
