import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/noImage.png'; // Replace with your default image path
import { insertPictureTopic } from '../../services/api_picture_topic'; // API service to create PictureTopic
import { API_URL_IMAGE, uploadFile } from '../../services/api';

const PictureTopicCreate = () => {
  const navigate = useNavigate();
  const [filePreview, setFilePreview] = useState(noImage);
  const [formData, setFormData] = useState({
    name: { vi: '', en: '' },
    desc: { vi: '', en: '' },
    key: '', // New key field
    thumb: '',
    pictureArtIds: [], // List of PictureArt IDs
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [field]: lang ? { ...prevData[field], [lang]: value } : value,
    }));
  };

  // Handle file upload
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const response = await uploadFile(file);
          const fileResponse = response.data;
          setFilePreview(fileResponse);

          setFormData((prevFormData) => ({
            ...prevFormData,
            thumb: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const updatedFormData = {
        ...formData,
        pictureArtIds: formData.pictureArtIds.map((id) => parseInt(id, 10)).filter((id) => !isNaN(id)),
      };
      const result = await insertPictureTopic(updatedFormData); // API call to create a topic
      if (result.success === 200) {
        console.log('form-data-picture-topic', formData)
        navigate('/admin/picture-topic'); // Redirect to the Picture Topics list
      } else {
        console.error('Error creating PictureTopic:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color"></div>
      <form
        onSubmit={handleSubmit}
        className="w-1/2 mt-[50px] mx-auto overflow-auto p-6 bg-white rounded shadow-md"
      >
        <h2 className="text-2xl font-bold text-center mb-6">Create Picture Topic</h2>

        {/* Name (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Name (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Name (English) */}
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Name (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Description (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="desc.vi" className="block mb-2 font-medium">
            Description (Vietnamese)
          </label>
          <textarea
            id="desc.vi"
            name="desc.vi"
            value={formData.desc.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows="3"
            required
          />
        </div>

        {/* Description (English) */}
        <div className="mb-4">
          <label htmlFor="desc.en" className="block mb-2 font-medium">
            Description (English)
          </label>
          <textarea
            id="desc.en"
            name="desc.en"
            value={formData.desc.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows="3"
            required
          />
        </div>

        {/* Key */}
        <div className="mb-4">
          <label htmlFor="key" className="block mb-2 font-medium">
            Key
          </label>
          <input
            type="text"
            id="key"
            name="key"
            value={formData.key}
            onChange={(e) =>
              setFormData((prevFormData) => ({ ...prevFormData, key: e.target.value }))
            }
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Thumbnail */}
        <div className="mb-4">
          <label htmlFor="thumb" className="block mb-2 font-medium">
            Thumbnail
          </label>
          <input
            type="file"
            id="thumb"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none"
          />
          <div className="mt-4">
            <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="w-32 h-32 object-cover" />
          </div>
        </div>

        {/* Picture Art IDs */}
        <div className="mb-4">
          <label htmlFor="pictureArtIds" className="block mb-2 font-medium">
            Picture Art IDs (Comma-separated)
          </label>
          <input
            type="text"
            id="pictureArtIds"
            name="pictureArtIds"
            value={formData.pictureArtIds.join(',')}
            onChange={(e) =>
              setFormData({ ...formData, pictureArtIds: e.target.value.split(',').map((id) => id.trim()) })
            }
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Submit */}
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Create Picture Topic
        </button>
      </form>
    </div>
  );
};

export default PictureTopicCreate;
