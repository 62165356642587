import React, { useEffect, useState } from 'react';
import { Button, Table, Typography } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getHeaderSettingById } from '../../services/api_header_setting';

const HeaderSettingList = () => {
    const [headerData, setHeaderData] = useState(null);
    const navigate = useNavigate();

    // Fetch data from the API
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get('http://localhost:8081/api/header-settings/1');
    //             setHeaderData(response.data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    useEffect(() => {
        const fetchHeaderSetting = async () => {
          try {
            const data = await getHeaderSettingById(1); // Replace `1` with the actual ID
            console.log('Header Setting:', data);
            setHeaderData(data)
          } catch (error) {
            console.error(error);
          }
        };
      
        fetchHeaderSetting();
      }, []);

    // Columns for the PictureArt table
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name (EN)',
            dataIndex: ['name', 'en'],
            key: 'name_en',
        },
        {
            title: 'Name (VI)',
            dataIndex: ['name', 'vi'],
            key: 'name_vi',
        },
        {
            title: 'Artist (EN)',
            dataIndex: ['artist', 'en'],
            key: 'artist_en',
        },
        {
            title: 'Artist (VI)',
            dataIndex: ['artist', 'vi'],
            key: 'artist_vi',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Material (EN)',
            dataIndex: ['material', 'en'],
            key: 'material_en',
        },
        {
            title: 'Material (VI)',
            dataIndex: ['material', 'vi'],
            key: 'material_vi',
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumb',
            key: 'thumb',
            render: (thumb) => <img src={thumb} alt="Thumbnail" width={50} height={50} />,
        },
    ];

   // if (!headerData) return <p>Loading...</p>;
   const handleInsert = () => {
    navigate('/admin/header-setting/insert');
  };
    return (
        <div className='flex flex-col w-full h-auto bg-white'>
            <div className="w-full h-[50px] bg-base_color">

            </div>
            <div className="flex justify-start mt-5 ml-5 md:justify-center sm:justify-center lg:justify-start">
                {/* <p className="font-bold">Tạo tài khoản đăng ký</p> */}
                <Button onClick={handleInsert} className="w-[160px] ml-5 font-bold text-center mb-5 bg-blue-500" type="primary" >
                    Tạo mới
                </Button>

            </div>
            {/* Displaying the title and description in English and Vietnamese */}
            
             {headerData&& <div className='ml-5'>

                <Typography.Title level={2}>{headerData?.title.en} / {headerData?.title.vi}</Typography.Title>
                <Typography.Paragraph>
                    {headerData?.desc.en} / {headerData?.desc.vi}
                </Typography.Paragraph>

            </div>}


            {/* PictureArt Table */}
            <Table
                dataSource={headerData?.pictureArtList}
                columns={columns}
                rowKey="id"
                bordered
                pagination={false}
            />
        </div>
    );
};



export default HeaderSettingList