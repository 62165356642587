import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Tag, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { findAllPictureTopics, deletePictureTopic } from '../../services/api_picture_topic'; // API services
import { API_URL_IMAGE } from '../../services/api';

const PictureTopicList = () => {
  const [pictureTopicList, setPictureTopicList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  // Fetch data for Picture Topics
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await findAllPictureTopics(); // Fetch all topics
        setPictureTopicList(response.data || []);
      } catch (error) {
        console.error('Error fetching PictureTopic data:', error);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/admin/picture-topic/update/${id}`);
  };

  const handleInsert = () => {
    navigate(`/admin/picture-topic/create-new`);
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deletePictureTopic(deleteId); // API call to delete the topic
      const updatedList = await findAllPictureTopics(); // Fetch updated data
      setPictureTopicList(updatedList.data || []);
      setIsModalVisible(false);
      setDeleteId(null);
    } catch (error) {
      console.error('Error deleting PictureTopic:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDeleteId(null);
  };

  // Columns configuration for the table
  // const columns = [
  //   { title: 'ID', dataIndex: 'id', key: 'id' },
  //   { title: 'Tên chủ đề (VN)', dataIndex: ['name', 'vi'], key: 'name_vi' },
  //   { title: 'Tên chủ đề (EN)', dataIndex: ['name', 'en'], key: 'name_en' },
  //   {
  //     title: 'Thumbnail',
  //     dataIndex: 'thumb',
  //     key: 'thumb',
  //     render: (thumb) => (
  //       <img
  //         src={thumb}
  //         alt="thumbnail"
  //         style={{ width: 50, height: 50, objectFit: 'cover' }}
  //       />
  //     ),
  //   },
  //   {
  //     title: 'Picture Art IDs',
  //     dataIndex: 'pictureArtIds',
  //     key: 'pictureArtIds',
  //     render: (ids) => (
  //       <Space size="small">
  //         {ids.map((id) => (
  //           <Tag color="blue" key={id}>
  //             {id}
  //           </Tag>
  //         ))}
  //       </Space>
  //     ),
  //   },
  //   {
  //     title: 'Hành động',
  //     key: 'actions',
  //     render: (text, record) => (
  //       <Space size="middle">
  //         <Button
  //           className="text-white bg-edit"
  //           type="primary"
  //           onClick={() => handleEdit(record.id)}
  //         >
  //           Cập nhật
  //         </Button>
  //         <Button
  //           className="text-white bg-delete"
  //           type="danger"
  //           onClick={() => confirmDelete(record.id)}
  //         >
  //           Xóa
  //         </Button>
  //       </Space>
  //     ),
  //   },
  // ];
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Tên chủ đề (VN)', dataIndex: ['name', 'vi'], key: 'name_vi' },
    { title: 'Tên chủ đề (EN)', dataIndex: ['name', 'en'], key: 'name_en' },
    {
      title: 'Thumbnail',
      dataIndex: 'thumb',
      key: 'thumb',
      render: (thumb) => (
        <img
          src={API_URL_IMAGE +  thumb}
          alt="thumbnail"
          style={{ width: 50, height: 50, objectFit: 'cover' }}
        />
      ),
    },
    {
      title: 'Picture Art IDs',
      dataIndex: 'pictureArtIds',
      key: 'pictureArtIds',
      render: (ids) => (
        <Space size="small">
          {ids.map((id) => (
            <Tag color="blue" key={id}>
              {id}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: 'Key', // Add the Key column
      dataIndex: 'key', // Assuming 'key' is part of the data object
      key: 'key', // You can customize this key if needed
    },
    {
      title: 'Hành động',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="text-white bg-edit"
            type="primary"
            onClick={() => handleEdit(record.id)}
          >
            Cập nhật
          </Button>
          <Button
            className="text-white bg-delete"
            type="danger"
            onClick={() => confirmDelete(record.id)}
          >
            Xóa
          </Button>
        </Space>
      ),
    },
  ];
  

  return (
    <div className="flex flex-col w-full h-auto bg-white">
      <div className="w-full h-[50px] bg-base_color"></div>
      <div className="flex justify-start mt-5 ml-5">
        <Button
          onClick={handleInsert}
          className="w-[160px] ml-5 font-bold text-center mb-5 bg-blue-500"
          type="primary"
        >
          Tạo mới
        </Button>
      </div>
      <Table
        style={{ width: '100%', fontFamily: 'Courier New' }}
        dataSource={pictureTopicList}
        columns={columns}
        rowKey="id"
      />
      <Modal
        title="Xác nhận xóa"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Xóa"
        cancelText="Hủy"
      >
        <p>Bạn có chắc chắn muốn xóa chủ đề này?</p>
      </Modal>
    </div>
  );
};

export default PictureTopicList;
