import { useNavigate } from 'react-router-dom';
import { IoMdSearch } from 'react-icons/io';
import { Button, Space, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { convertDateFormat, deleteCategory, getCategory } from '../../services/api'; // Assuming deleteCategory is the correct function
import { deleteMaterial, getMaterials } from '../../services/api_material';

const MaterialList = () => {
  const [isOn, setIsOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  const handleInsert = () => {
    navigate("/admin/materials/insert");
  };

  const handleSearch = () => {
    // Implement search logic here
  };

  const handleEdit = (id) => {
    console.log('Edit clicked for ID:', id);
    navigate(`/admin/materials/edit/${id}`);
  };

  const showDeleteModal = (id) => {
    setDeleteId(id); // Store the ID of the item to delete
    setIsModalVisible(true); // Show the modal
  };

  const handleConfirmDelete = async () => {
    if (deleteId) {
      try {
        const response = await deleteMaterial(deleteId); // Call the API to delete the item
        console.log('Delete response:', response);
        refreshData(); // Refresh the data
        setIsModalVisible(false); // Hide the modal
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false); // Hide the modal
  };

  const refreshData = async () => {
    try {
      const response = await getMaterials();
      console.log('API Response:', response); // Log the full response
      if(response.success == 200){

        setCategoryList(response.data); // Ensure we're setting only the 'data' array

      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tên hạng mục (VN)',
      dataIndex: ['name', 'vi'], // Nested data access
      key: 'name_vi',
    },
    {
      title: 'Tên hạng mục (EN)',
      dataIndex: ['name', 'en'], // Nested data access
      key: 'name_en',
    },
    {
      title: 'Mô tả (VN)',
      dataIndex: ['desc', 'vi'], // Nested data access
      key: 'desc_vi',
    },
    {
      title: 'Mô tả (EN)',
      dataIndex: ['desc', 'en'], // Nested data access
      key: 'desc_en',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => (text ? convertDateFormat(text) : 'N/A'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      render: (status) => (
        <span style={{ color: status ? 'green' : 'red' }}>
          {status ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      title: 'Nhóm',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Hành động',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="text-white bg-edit"
            type="primary"
            onClick={() => handleEdit(record.id)}
          >
            Cập nhật
          </Button>
          <Button
            className="mr-5 text-white bg-delete"
            type="danger"
            onClick={() => showDeleteModal(record.id)}
          >
            Xóa
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='flex flex-col w-full h-auto bg-white'>
      <div className="w-full h-[50px] bg-base_color"></div>
      <div className="flex justify-start mt-5 ml-5">
        <Button
          className="px-3 py-2 ml-5 font-bold text-center mb-5 bg-blue-500"
          type="primary"
          onClick={handleInsert}
        >
          Tạo một chất liệu (Material)
        </Button>
      </div>
      <div className='h-[1px] bg-base_color w-full'></div>
      <div className='flex flex-row m-5'>
        <div className='flex items-center gap-3'>
          <input
            type="radio"
            className='w-4 h-4 p-3 text-green-500'
            checked
          />
          <label className='flex items-center'> Tất cả </label>
        </div>
        <div className='flex ml-5'>
          <form onSubmit={handleSearch}>
            <div className="flex items-center border border-gray-300 rounded-md">
              <input
                type="text"
                className="w-full px-3 py-1 outline-none rounded-tl-md rounded-bl-md"
                placeholder="Tìm kiếm"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button
                className="p-2 text-gray-500 scale-105 bg-white border shadow-sm hover:bg-gray-100 rounded-tr-md rounded-br-md"
              >
                <IoMdSearch />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        {categoryList.length > 0 ? (
          <Table
            style={{ width: '100%', fontFamily: 'Courier New' }}
            dataSource={categoryList}
            columns={columns}
            rowKey="id"
          />
        ) : (
          <p>Loading categories...</p>
        )}
      </div>
      {/* Modal for delete confirmation */}
      <Modal
        title="Xác nhận xóa"
        visible={isModalVisible}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText="Xóa"
        cancelText="Hủy"
      >
        <p>Bạn có chắc chắn muốn xóa mục này không?</p>
      </Modal>
    </div>
  );
};

export default MaterialList;
