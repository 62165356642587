import React from 'react'
import { useParams } from 'react-router-dom';

const ItineraryEditPage = () => {

    const { id } = useParams();

  return (
    <div>ItineraryEditPage</div>
  )
}

export default ItineraryEditPage