import { API_URL } from "./api";
// api.js
import axios from 'axios';
// Add the following lines to set the CORS headers
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // Replace '*' with the allowed origin(s) of your backend API
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE'; // Specify the allowed HTTP methods
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'; // Specify the allowed headers
export const getArtist = async () => {
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/artist/findAll`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  
  }


  export const createArtist = async (artistData) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/artist/insert?token=${token}`, artistData, config);
      console.log("artistData",artistData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const updateArtist = async (artistData) => {

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/artist/update?token=${token}`, artistData);
      console.log("artistData",artistData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const findArtistById = async (id) => {
    try {
      //const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/artist/findById?id=${id}`, {
        withCredentials: true,
      });
      
      return response.data;
    } catch (error) {
      throw error;
    }
  
  }

  export const deleteArtist = async (artistID) => {
    try {
      const response = await axios.put(`${API_URL}/artist/delete/${artistID}`, {
        withCredentials: true,
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  };
