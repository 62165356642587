import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/avatar-default-icon.png';
import { createFeedback } from '../../services/api_feedback';
import { API_URL_IMAGE, uploadFile } from '../../services/api';

const FeedbackCreate = () => {
  const navigate = useNavigate();
  const [filePreview, setFilePreview] = useState(noImage); // Thumbnail preview

  const [formData, setFormData] = useState({
    name: { vi: '', en: '' },
    title: { vi: '', en: '' },
    comment: { vi: '', en: '' },
    rating: 0, // Default rating
    thumb: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setFormData((prevData) => ({
      ...prevData,
      [field]: lang ? { ...prevData[field], [lang]: value } : value,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const response = await uploadFile(file);
          const fileResponse = response.data;
          setFilePreview(fileResponse);

          setFormData((prevFormData) => ({
            ...prevFormData,
            thumb: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await createFeedback(formData); // API call to create feedback
      if (result.success === 200) {
        navigate('/admin/feedback'); // Redirect to feedbacks list page
      } else {
        console.error('Error creating feedback:', result.message);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="flex flex-col w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color"></div>
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        {/* Name (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Tên người dùng (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Name (English) */}
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Tên người dùng (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Title (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="title.vi" className="block mb-2 font-medium">
            Tiêu đề (Vietnamese)
          </label>
          <input
            type="text"
            id="title.vi"
            name="title.vi"
            value={formData.title.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Title (English) */}
        <div className="mb-4">
          <label htmlFor="title.en" className="block mb-2 font-medium">
            Tiêu đề (English)
          </label>
          <input
            type="text"
            id="title.en"
            name="title.en"
            value={formData.title.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Comment (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="comment.vi" className="block mb-2 font-medium">
            Bình luận (Vietnamese)
          </label>
          <textarea
            id="comment.vi"
            name="comment.vi"
            value={formData.comment.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows="4"
            required
          />
        </div>

        {/* Comment (English) */}
        <div className="mb-4">
          <label htmlFor="comment.en" className="block mb-2 font-medium">
            Bình luận (English)
          </label>
          <textarea
            id="comment.en"
            name="comment.en"
            value={formData.comment.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            rows="4"
            required
          />
        </div>

        {/* Rating */}
        <div className="mb-4">
          <label htmlFor="rating" className="block mb-2 font-medium">
            Đánh giá (1-5)
          </label>
          <input
            type="number"
            id="rating"
            name="rating"
            value={formData.rating}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            min="1"
            max="5"
            step="0.1"
            required
          />
        </div>

        {/* Thumbnail */}
        <div className="mb-4">
          <label htmlFor="thumb" className="block mb-2 font-medium">
            Ảnh đại diện
          </label>
          <input
            type="file"
            id="thumb"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none"
          />
          <div className="mt-4">
            <img src={API_URL_IMAGE +  filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover" />
          </div>
        </div>

        {/* Submit */}
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Tạo mới phản hồi
        </button>
      </form>
    </div>
  );
};

export default FeedbackCreate;
