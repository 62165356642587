import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Modal, message } from 'antd';
import { IoMdSearch } from 'react-icons/io';
import { API_URL_IMAGE, convertDateFormat, deleteCategory, getCategory } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const CategoriesList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInsert = () => {
    navigate('/admin/categories/create-new');
  };

  const refreshData = async () => {
    try {
      const response = await getCategory();
      if (response.success === 200) {
        setCategoryList(response.data);
      } else {
        console.error('Error: Invalid response data');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const showDeleteConfirm = (id) => {
    setCurrentDeleteId(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteCategory(currentDeleteId);
      if (response.success === 200) {
        message.success('Category deleted successfully!');
        refreshData();
      } else {
        message.error('Failed to delete category!');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
      message.error('Error occurred while deleting the category.');
    } finally {
      setIsModalVisible(false);
      setCurrentDeleteId(null);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tên hạng mục (VN)',
      dataIndex: ['name', 'vi'],
      key: 'name_vi',
    },
    {
      title: 'Tên hạng mục (EN)',
      dataIndex: ['name', 'en'],
      key: 'name_en',
    },
    {
      title: 'Mô tả (VN)',
      dataIndex: ['desc', 'vi'],
      key: 'desc_vi',
    },
    {
      title: 'Mô tả (EN)',
      dataIndex: ['desc', 'en'],
      key: 'desc_en',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => (text ? convertDateFormat(text) : 'N/A'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      render: (status) => (
        <span style={{ color: status ? 'green' : 'red' }}>
          {status ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    // {
    //   title: 'Nhóm',
    //   dataIndex: 'group',
    //   key: 'group',
    // },
    {
      title: 'Thumbnail',
      dataIndex: 'thumb',
      key: 'thumb',
      render: (thumb) => (
        <img
          src={API_URL_IMAGE +  thumb}
          alt="thumbnail"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      title: 'Hành động',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="text-white bg-edit"
            type="primary"
            onClick={() => navigate(`/admin/categories/update/${record.id}`)}
          >
            Cập nhật
          </Button>
          <Button
            className="mr-5 text-white bg-delete"
            type="danger"
            onClick={() => showDeleteConfirm(record.id)}
          >
            Xóa
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="flex flex-col w-full h-auto bg-white">
      <div className="w-full h-[50px] bg-base_color"></div>
      <div className="flex justify-start mt-5 ml-5 md:justify-center sm:justify-center lg:justify-start">
        <Button
          className="w-[160px] ml-5 font-bold text-center mb-5 bg-blue-500"
          type="primary"
          onClick={handleInsert}
        >
          Tạo danh mục
        </Button>
      </div>
      <div className="h-[1px] bg-base_color w-full"></div>
      <div className="flex flex-row m-5">
        <div className="flex items-center gap-3">
          <input
            type="radio"
            className="w-4 h-4 p-3 text-green-500 focus:bg-green-500"
            checked
          />
          <label className="flex items-center"> Tất cả </label>
        </div>
        <div className="flex ml-5">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex items-center border border-gray-300 rounded-md">
              <input
                type="text"
                className="w-full px-3 py-1 outline-none rounded-tl-md rounded-bl-md"
                placeholder="Tìm kiếm"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button className="p-2 text-gray-500 scale-105 bg-white border shadow-sm hover:bg-gray-100 rounded-tr-md rounded-br-md">
                <IoMdSearch />
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex w-[100%] flex-row justify-center">
        {categoryList.length > 0 ? (
          <Table
            style={{ width: '100%', fontFamily: 'Courier New' }}
            dataSource={categoryList}
            columns={columns}
            rowKey="id"
          />
        ) : (
          <p>Loading categories...</p>
        )}
      </div>

      <Modal
        title="Xác nhận xóa"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={() => setIsModalVisible(false)}
        okText="Xóa"
        cancelText="Hủy"
      >
        <p>Bạn có chắc chắn muốn xóa danh mục này không?</p>
      </Modal>
    </div>
  );
};

export default CategoriesList;
