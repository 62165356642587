import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { findPictureArtById, updatePictureArt } from '../../services/api_picture_art'; // Replace with your API service
import noImage from '../../assets/noImage.png';
import { API_URL_IMAGE, getCategory, uploadFile } from '../../services/api';
import { getMaterials } from '../../services/api_material';
import { getArtist } from '../../services/api_artist'; // Replace with your artist fetching API service

const PictureArtEdit = () => {
  const { id } = useParams(); // Get the picture art ID from the URL
  const navigate = useNavigate();
  
  const [pictureArt, setPictureArt] = useState({
    id:id,
    name: { vi: '', en: '' },
    artistID: '',
    thumb: '',
    categoriesID: '',
    materialsID: '',
    size: '',
  });
  
  const [filePreview, setFilePreview] = useState(noImage); // Thumbnail preview
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [artists, setArtists] = useState([]); // List of artists

  // Fetch data for categories, materials, and artists
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch categories
        const categoriesResponse = await getCategory();
        if (categoriesResponse.success === 200) {
          setCategories(categoriesResponse.data || []);
        }

        // Fetch materials
        const materialsResponse = await getMaterials();
        if (materialsResponse.success === 200) {
          setMaterials(materialsResponse.data || []);
        }

        // Fetch artists
        const artistsResponse = await getArtist();
        if (artistsResponse.success === 200) {
          setArtists(artistsResponse.data || []);
        }

        // Fetch picture art details for editing
        const pictureArtResponse = await findPictureArtById(id);
        if (pictureArtResponse.success === 200) {
          const fetchedData = pictureArtResponse.data;
          setPictureArt(fetchedData);
          setFilePreview(fetchedData.thumb || noImage);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, lang] = name.split('.');
    setPictureArt((prev) => ({
      ...prev,
      [field]: lang ? { ...prev[field], [lang]: value } : value,
    }));
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       setFilePreview(event.target.result);
  //       setPictureArt((prev) => ({ ...prev, thumb: event.target.result }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        setPictureArt((prev) => ({ ...prev, thumb: event.target.result }));

        try {
          const response = await uploadFile(file);
          const fileResponse =  response.data;
          setFilePreview(fileResponse);

          setPictureArt((prevFormData) => ({
            ...prevFormData,
            thumb: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updatePictureArt(pictureArt); // Call the update API function
      navigate('/admin/picture-art'); // Redirect after editing
    } catch (error) {
      console.error('Error updating PictureArt:', error);
    }
  };

  return (
    <div className="flex flex-col w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color" />
      <form onSubmit={handleSubmit} className="w-full flex flex-col max-w-4xl mx-auto bg-white p-6 rounded shadow">
        <h2 className="text-xl font-bold mb-4">Edit Picture Art</h2>
        <div className="mb-4">
          <label htmlFor="name.vi" className="block font-medium">Name (Vietnamese)</label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={pictureArt.name.vi}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name.en" className="block font-medium">Name (English)</label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={pictureArt.name.en}
            onChange={handleChange}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="artistID" className="block font-medium">Select Artist</label>
          <select
            id="artistID"
            name="artistID"
            value={pictureArt.artistID}
            onChange={handleChange}
            className="w-full p-2 border rounded appearance-none"
            required
          >
            <option value="">-- Select Artist --</option>
            {artists.map((artist) => (
              <option key={artist.id} value={artist.id}>
                {artist.name.vi}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="categoriesID" className="block font-medium">Select Category</label>
          <select
            id="categoriesID"
            name="categoriesID"
            value={pictureArt.categoriesID}
            onChange={handleChange}
            className="w-full p-2 border rounded appearance-none"
            required
          >
            <option value="">-- Select Category --</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name.vi}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="materialsID" className="block font-medium">Select Material</label>
          <select
            id="materialsID"
            name="materialsID"
            value={pictureArt.materialsID}
            onChange={handleChange}
            className="w-full p-2 border rounded appearance-none"
            required
          >
            <option value="">-- Select Material --</option>
            {materials.map((material) => (
              <option key={material.id} value={material.id}>
                {material.name.vi}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="thumb" className="block font-medium">Thumbnail</label>
          <input
            type="file"
            id="thumb"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full p-2"
          />
          <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover" />
        </div>
        <div className="mb-4">
          <label htmlFor="size" className="block font-medium">Size</label>
          <input
            type="text"
            id="size"
            name="size"
            value={pictureArt.size}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
          Update Picture Art
        </button>
      </form>
    </div>
  );
};

export default PictureArtEdit;
