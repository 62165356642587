import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import noImage from '../../assets/avatar-default-icon.png';
import { findArtistById, updateArtist } from '../../services/api_artist';
import { API_URL_IMAGE, uploadFile } from '../../services/api';

const ArtistEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get artist ID from route params
  const [filePreview, setFilePreview] = useState(noImage); // Thumbnail preview
  const [formData, setFormData] = useState({
    id:id,
    name: { vi: '', en: '' },
    journey: { vi: '', en: '' },
    avatar: '',
    active: true,
  });                                             

  useEffect(() => {
    const fetchArtistData = async () => {
      try {
        const response = await findArtistById(id);
        if (response.success === 200) {
          const artist = response.data;
          setFormData({
            id: artist.id,
            name: artist.name || { vi: '', en: '' },
            journey: artist.journey || { vi: '', en: '' },
            avatar: artist.avatar || noImage,
            active: artist.active || false,
          });
          setFilePreview(artist.avatar || noImage);
        }
      } catch (error) {
        console.error('Error fetching artist data:', error);
      }
    };
    fetchArtistData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      const [field, lang] = name.split('.');
      setFormData((prevData) => ({
        ...prevData,
        [field]: lang ? { ...prevData[field], [lang]: value } : value,
      }));
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const response = await uploadFile(file);
          const fileResponse =  response.data;
          setFilePreview(fileResponse);

          setFormData((prevFormData) => ({
            ...prevFormData,
            avatar: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('form-data', formData);
    try {
      const response = await updateArtist(formData);
      if (response.success === 200) {
        navigate('/admin/artist'); // Redirect to artists list page
      } else {
        console.error('Error updating artist:', response.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="flex flex-col w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color"></div>
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Tên nghệ sĩ (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Tên nghệ sĩ (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="journey.vi" className="block mb-2 font-medium">
            Hành trình (Vietnamese)
          </label>
          <input
            type="text"
            id="journey.vi"
            name="journey.vi"
            value={formData.journey.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="journey.en" className="block mb-2 font-medium">
            Hành trình (English)
          </label>
          <input
            type="text"
            id="journey.en"
            name="journey.en"
            value={formData.journey.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="avatar" className="block mb-2 font-medium">
            Ảnh đại diện
          </label>
          <input
            type="file"
            id="avatar"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none"
          />
          <div className="mt-4">
            <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover" />
          </div>
        </div>

        <div className="mb-4">
          <label htmlFor="active" className="inline-flex items-center">
            <input
              type="checkbox"
              id="active"
              name="active"
              checked={formData.active}
              onChange={handleChange}
              className="text-indigo-600 border-gray-300 rounded shadow-sm focus:ring focus:ring-offset-0"
            />
            <span className="ml-2 font-medium">Hoạt động</span>
          </label>
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Cập nhật nghệ sĩ
        </button>
      </form>
    </div>
  );
};

export default ArtistEdit;
