import { Button, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { convertDateFormat, deleteCategory, getCategory } from '../../services/api'; // Assuming deleteCategory is the correct function
import { useNavigate } from 'react-router-dom';

const CategoryTable = () => {
  const [categoryList, setCategoryList] = useState([]);
  const navigate = useNavigate();

  const handleEdit = (id) => {
    console.log('Edit clicked for ID:', id);
    navigate(`/admin/categories/update/${id}`);
  };

  const handleDelete = async (id) => {
    console.log('Delete clicked for ID:', id);
    const response = await deleteCategory(id);  // Ensure deleteCategory is the correct function for deleting categories
    refreshData();
    console.log('Delete response:', response);
  };

  const refreshData = async () => {
    try {
      const response = await getCategory();
      console.log('API Response:', response);  // Log the full response
      if (response.success === 200) {
        setCategoryList(response.data); // Ensure we're setting only the 'data' array
      } else {
        console.error('Error: Invalid response data');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Tên hạng mục (VN)',
      dataIndex: ['name', 'vi'],  // Nested data access
      key: 'name_vi',
    },
    {
      title: 'Tên hạng mục (EN)',
      dataIndex: ['name', 'en'],  // Nested data access
      key: 'name_en',
    },
    {
      title: 'Mô tả (VN)',
      dataIndex: ['desc', 'vi'],  // Nested data access
      key: 'desc_vi',
    },
    {
      title: 'Mô tả (EN)',
      dataIndex: ['desc', 'en'],  // Nested data access
      key: 'desc_en',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text) => (text ? convertDateFormat(text) : 'N/A'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      render: (status) => (
        <span style={{ color: status ? 'green' : 'red' }}>
          {status ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      title: 'Nhóm',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Hành động',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button
            className="text-white bg-edit"
            type="primary"
            onClick={() => handleEdit(record.id)}
          >
            Cập nhật
          </Button>
          <Button
            className="mr-5 text-white bg-delete"
            type="danger"
            onClick={() => handleDelete(record.id)}
          >
            Xóa
          </Button>
          <Button
            className="mr-5 text-white bg-emerald-500"
            onClick={() => handleDelete(record.id)}
          >
            Kích hoạt
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="w-full flex justify-center items-center">
      {categoryList.length > 0 ? (  // Ensure categoryList is not empty
        <Table
          style={{ width: '100%', fontFamily: 'Courier New' }}
          rowClassName={(record, index) => (index % 2 === 0 ? 'row-even' : 'row-odd')}
          dataSource={categoryList}
          columns={columns}
          rowKey="id"  // Ensure each row has a unique key
        />
      ) : (
        <p>Loading categories...</p>  // Show a loading message if data is not available
      )}
    </div>
  );
};

export default CategoryTable;
