import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/avatar-default-icon.png';
import { createArtist } from '../../services/api_artist';
import { API_URL_IMAGE, uploadFile } from '../../services/api';

const ArtistCreate = () => {
  const navigate = useNavigate();
  const [filePreview, setFilePreview] = useState(noImage); // Thumbnail preview

  const [file, setFile] = useState(noImage); // Placeholder for avatar
  const [formData, setFormData] = useState({
    name: { vi: '', en: '' },
    journey: { vi: '', en: '' },
    avatar: '',
    active: false, // Default active status
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      const [field, lang] = name.split('.');
      setFormData((prevData) => ({
        ...prevData,
        [field]: lang ? { ...prevData[field], [lang]: value } : value,
      }));
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       setFile(event.target.result);
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         avatar: event.target.result,
  //       }));
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        setFormData((prev) => ({ ...prev, avatar: event.target.result }));

        try {
          const response = await uploadFile(file);
          const fileResponse =  response.data;
          setFilePreview(fileResponse);

          setFormData((prevFormData) => ({
            ...prevFormData,
            avatar: fileResponse,
          }));
        } catch (err) {
          console.error('Error uploading file:', err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await createArtist(formData); // Adjust API call to create artist
    if (result.success === 200) {
      navigate('/admin/artist'); // Redirect to artists list page
    } else {
      console.error('Error creating artist:', result.message);
    }
  };

  return (
    <div className="flex flex-col w-full h-auto bg-[#EEA048]">
      <div className="w-full h-[50px] bg-base_color"></div>
      <form onSubmit={handleSubmit} className="w-full mt-[50px] max-w-lg mx-auto overflow-auto">
        {/* Name (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="name.vi" className="block mb-2 font-medium">
            Tên nghệ sĩ (Vietnamese)
          </label>
          <input
            type="text"
            id="name.vi"
            name="name.vi"
            value={formData.name.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Name (English) */}
        <div className="mb-4">
          <label htmlFor="name.en" className="block mb-2 font-medium">
            Tên nghệ sĩ (English)
          </label>
          <input
            type="text"
            id="name.en"
            name="name.en"
            value={formData.name.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Journey (Vietnamese) */}
        <div className="mb-4">
          <label htmlFor="journey.vi" className="block mb-2 font-medium">
            Hành trình (Vietnamese)
          </label>
          <input
            type="text"
            id="journey.vi"
            name="journey.vi"
            value={formData.journey.vi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Journey (English) */}
        <div className="mb-4">
          <label htmlFor="journey.en" className="block mb-2 font-medium">
            Hành trình (English)
          </label>
          <input
            type="text"
            id="journey.en"
            name="journey.en"
            value={formData.journey.en}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        {/* Avatar */}
        <div className="mb-4">
          <label htmlFor="avatar" className="block mb-2 font-medium">
            Ảnh đại diện
          </label>
          <input
            type="file"
            id="avatar"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded shadow-sm focus:outline-none"
          />
          <div className="mt-4">
          <img src={API_URL_IMAGE + filePreview} alt="Thumbnail Preview" className="mt-2 w-32 h-32 object-cover" />
          </div>
        </div>

        {/* Active */}
        <div className="mb-4">
          <label htmlFor="active" className="inline-flex items-center">
            <input
              type="checkbox"
              id="active"
              name="active"
              checked={formData.active}
              onChange={handleChange}
              className="text-indigo-600 border-gray-300 rounded shadow-sm focus:ring focus:ring-offset-0"
            />
            <span className="ml-2 font-medium">Hoạt động</span>
          </label>
        </div>

        {/* Submit */}
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Tạo mới nghệ sĩ
        </button>
      </form>
    </div>
  );
};

export default ArtistCreate;
