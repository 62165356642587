import { Button, Space, Table, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { findAllFeedbacks, deleteFeedback } from '../../services/api_feedback'; // Replace with your actual API functions
import { API_URL_IMAGE } from '../../services/api';

const FeedbackList = () => {
    const [feedbackList, setFeedbackList] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);
    const navigate = useNavigate();

    const handleInsert = () => {
        navigate('/admin/feedback/create-new');
    };

    const handleEdit = (id) => {
        navigate(`/admin/feedback/update/${id}`);
    };

    const showDeleteModal = (id) => {
        setSelectedFeedbackId(id); // Store the ID of the feedback to delete
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedFeedbackId(null);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteFeedback(selectedFeedbackId); // Call your delete API function
            refreshData(); // Refresh the feedback list after deletion
            setIsModalVisible(false);
            setSelectedFeedbackId(null);
        } catch (error) {
            console.error('Error deleting feedback:', error);
        }
    };

    const refreshData = async () => {
        try {
            const response = await findAllFeedbacks();
            setFeedbackList(response.data); // Ensure the response data contains the list of feedback
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name (VN)',
            dataIndex: ['name', 'vi'], // Nested access to Vietnamese name
            key: 'name_vi',
        },
        {
            title: 'Name (EN)',
            dataIndex: ['name', 'en'], // Nested access to English name
            key: 'name_en',
        },
        {
            title: 'Title (VN)',
            dataIndex: ['title', 'vi'], // Nested access to Vietnamese title
            key: 'title_vi',
        },
        {
            title: 'Title (EN)',
            dataIndex: ['title', 'en'], // Nested access to English title
            key: 'title_en',
        },
        {
            title: 'Comment (VN)',
            dataIndex: ['comment', 'vi'], // Nested access to Vietnamese comment
            key: 'comment_vi',
        },
        {
            title: 'Comment (EN)',
            dataIndex: ['comment', 'en'], // Nested access to English comment
            key: 'comment_en',
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
        },
        {
            title: 'Thumbnail',
            dataIndex: 'thumb',
            key: 'thumb',
            render: (thumb) => (
                <img
                    src={API_URL_IMAGE +   thumb || 'https://example.com/default-thumbnail.png'}
                    alt="Thumbnail"
                    className="w-16 h-16 rounded-full object-cover"
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => handleEdit(record.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        danger
                        onClick={() => showDeleteModal(record.id)}
                    >
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div className="flex flex-col w-full h-auto bg-white">
            <div className="w-full h-[50px] bg-base_color"></div>
            <div className="flex justify-start mt-5 ml-5 md:justify-center sm:justify-center lg:justify-start">
                <Button
                    onClick={handleInsert}
                    className="w-[160px] ml-5 font-bold text-center mb-5 bg-blue-500"
                    type="primary"
                >
                    Add Feedback
                </Button>
            </div>
            <Table
                style={{ width: '100%', fontFamily: 'Courier New' }}
                dataSource={feedbackList}
                columns={columns}
                rowKey="id"
            />
            <Modal
                title="Confirm Deletion"
                visible={isModalVisible}
                onOk={handleConfirmDelete}
                onCancel={handleCancel}
                okText="Delete"
                okButtonProps={{ danger: true }}
                cancelText="Cancel"
            >
                <p>Are you sure you want to delete this feedback?</p>
            </Modal>
        </div>
    );
};

export default FeedbackList;
